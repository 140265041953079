.testimonial {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
  max-width: 100%;
  width: 400px;
}

.testimonial-body {
  padding: 40px 40px 80px;
}

.testimonial-body p {
  color: #555;
  font-size: 20px;
  line-height: 36px;
  margin: 0;
}

.testimonial-body .fa-quote-right {
  color: #eee;
  font-size: 40px;
  float: right;
}

.testimonial-footer {
  background-color: #686de0;
  color: #fff;
  padding: 40px;
  text-align: center;
}

.testimonial-footer img {
  border-radius: 50%;
  border: 5px solid #fff;
  height: 120px;
  width: 120px;
  margin-top: -100px;
}

.testimonial-footer h3 {
  margin: 10px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.testimonial-footer h4 {
  color: #b3b5ef;
  margin: 0;
  letter-spacing: 1px;
}
