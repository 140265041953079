* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

// written by me

.zoomButton {
  animation: zoomInOut 3s infinite;
  animation: col 3s infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes col {
  50.0% {
    background-color: #eeebfc;
  }
}

.fancyGradient {
  //// not that good. good only for buttons
  // background: linear-gradient(90deg, #0072ff 0%, #00d4ff 100%);
  background: linear-gradient(90deg, #000851 0%, #1cb5e0 100%);
  // has dark blue with green too
  // background: linear-gradient(90deg, #0700b8 0%, #00b862 100%);
  // background: linear-gradient(
  //   90deg,
  //   rgba(2, 0, 36, 1) 0%,
  //   rgba(9, 9, 121, 1) 35%,
  //   rgba(0, 212, 255, 1) 100%
  // );
}

.courseCardZoom {
  transition: transform 0.2s;
}

.courseCardZoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);
}

.rhap_time {
  color: #5850ec;
}

// for image header up down animation
@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.2, 1.2);
  }
  50% {
    transform: scale(1.4, 1.4);
  }
  75% {
    transform: scale(1.47, 1.47);
  }
  100% {
    transform: scale(1.5, 1.5);
  }
}

.infiniteZoomInOut {
  animation: zoom 1.2s infinite;
}

// Apex Chart's text doesn't have direct options to change color in react. So this color looks good on both dark & light theme
.apexcharts-text {
  fill: #777;
}
