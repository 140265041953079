/* custom-tree.css */

.node__root > circle {
  fill: red;
}

.node__branch > circle {
  fill: yellow;
}

.node__leaf > circle {
  fill: green;
}

/* Text in the pattern -- working well */
.rd3t-label__title {
  /* color: white; */
  fill: white;
}

/* Pattern Link -- didn't work much */
.rd3t-link {
  fill: white;
  stroke-width: 2;
  color: white;
}
